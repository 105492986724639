import React from "react"
import { float, logo } from "./styles/callButton.module.css"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button } from "react-bootstrap"

const callButton = () => (
  <Button href={"tel:0749387047"} className={float}>
    <FontAwesomeIcon icon={faPhone} className={`${logo} animate__heartBeat `} />
  </Button>
)

export default callButton

import React from "react"
import { Link } from "gatsby"
import logoImg from "../../image/logo_epaviste_idf_Dulin.jpg"
import { head, activeStyle, linkHeader } from "./styles/header.module.css"
import NavBar from "react-bootstrap/Navbar"
import NavDropdown from "react-bootstrap/NavDropdown"
import Nav from "react-bootstrap/Nav"

const header = () => {
  return (
    <header className="container">
      <NavBar bg="light" expand="lg" className={head}>
        <Link className="navbar-brand " to="/">
          <img
            src={logoImg}
            className="d-inline-block align-top img-fluid"
            alt="logo_epaviste_Dulin"
          />
        </Link>
        <NavBar.Toggle aria-controls="basic-navbar-nav" />
        <NavBar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            <Link
              className={`${linkHeader} nav-link`}
              activeClassName={activeStyle}
              to="/"
            >
              ACCUEIL
            </Link>
            <Link
              className={`${linkHeader} nav-link`}
              activeClassName={activeStyle}
              to="/notre-societe"
            >
              SOCIETE
            </Link>
            <NavDropdown
              className={linkHeader}
              title="DEPARTEMENTS"
              id="basic-nav-dropdown"
            >
              <Link
                className="dropdown-item"
                to="/departement/Paris"
                activeClassName={activeStyle}
              >
                Paris 75
              </Link>
              <Link
                className="dropdown-item"
                to="/departement/Essonne"
                activeClassName={activeStyle}
              >
                Essonne 91
              </Link>
              <Link
                className="dropdown-item"
                to="/departement/Haut-de-Seine"
                activeClassName={activeStyle}
              >
                Haut de Seine 92
              </Link>
              <Link
                className="dropdown-item"
                to="/departement/Saint-Saint-Denis"
                activeClassName={activeStyle}
              >
                Saint Saint Denis 93
              </Link>
              <Link
                className="dropdown-item"
                to="/departement/Val-de-Marne"
                activeClassName={activeStyle}
              >
                Val de Marrne 94
              </Link>
              <Link
                className="dropdown-item"
                to="/departement/Val-dOise"
                activeClassName={activeStyle}
              >
                Val d'Oise 95
              </Link>
              <Link
                className="dropdown-item"
                to="/departement/Seine-et-Marne"
                activeClassName={activeStyle}
              >
                Seine et Marne 77
              </Link>
              <Link
                className="dropdown-item"
                to="/departement/Yvelines"
                activeClassName={activeStyle}
              >
                Yvelines 78
              </Link>
            </NavDropdown>
            <Link
              className={`${linkHeader} nav-link`}
              activeClassName={activeStyle}
              to="/contact"
            >
              CONTACT
            </Link>
          </Nav>
        </NavBar.Collapse>
      </NavBar>
    </header>
  )
}

export default header

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Seo = ({ title, description }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)

  const {
    defaultTitle,
    defaultDescription,
    lang,
    siteLanguage,
    siteLocale,
    siteUrl,
    author,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    siteUrl: `${siteUrl}${pathname}`,
    lang: `${lang}`,
    siteLanguage: `${siteLanguage}`,
    siteLocale: `${siteLocale}`,
    author: `${author}`,
  }

  return (
    <Helmet title={seo.title}>
      {seo.title && <meta name="title" content={seo.title} />}
      {seo.siteUrl && <meta name="siteUrl" content={seo.siteUrl} />}
      {seo.lang && <meta name="lang" content={seo.lang} />}
      {seo.description && <meta name="description" content={seo.description} />}
      {seo.siteLanguage && (
        <meta name="siteLanguage" content={seo.siteLanguage} />
      )}
      {seo.siteLocale && <meta name="siteLocale" content={seo.siteLocale} />}
      {seo.author && <meta name="author" content={seo.author} />}
    </Helmet>
  )
}
export default Seo

Seo.propTypes = {
  title: PropTypes.string,
  siteUrl: PropTypes.string,
  description: PropTypes.string,
  siteLanguage: PropTypes.string,
  siteLocale: PropTypes.string,
  author: PropTypes.string,
}

Seo.defaultProps = {
  title: null,
  siteUrl: null,
  description: null,
  siteLanguage: null,
  siteLocale: null,
  author: null,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl
        siteLanguage
        siteLocale
        author
      }
    }
  }
`

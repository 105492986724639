import React from "react"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt, faMobileAlt } from "@fortawesome/free-solid-svg-icons"
import {
  background,
  block,
  icon,
  link,
  dulin,
} from "./styles/footer.module.css"

const footer = () => (
  <footer className={background}>
    <Container>
      <Row>
        <Col lg={4} md={4} sm>
          <div className={block}>
            <div>
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                size={"lg"}
                color={"#99bf38"}
                className={icon}
              />
              <a
                target="_parent"
                className={link}
                href="https://goo.gl/maps/XTy6FRJFDHskZCpJ6"
              >
                Epaviste Dulin
                <br />
                27 Avenue 8 Mai 1945
                <br />
                91100 CORBEIL ESSONNES
              </a>
            </div>
          </div>
        </Col>
        <Col lg={4} md={4} sm>
          <div className={block}>
            <div>
              <FontAwesomeIcon
                icon={faMobileAlt}
                size={"lg"}
                color={"#99bf38"}
                className={icon}
              />
              TEL :
              <a className={link} href={"tel:0749387047"}>
                07 49 38 70 47
              </a>
              <br />
              MAIL:
              <a className={link} href={"mailto:epaviste.dulin@gmail.com"}>
                epaviste.dulin@gmail.com
              </a>
            </div>
          </div>
        </Col>
        <Col lg={4} md={4} sm>
          <div className={block}>
            <strong className={dulin}>Epaviste Dulin</strong> &copy;
            <em id="copyright-year" /> |{" "}
            <Link to="/mentionLegales" className={link}>
              Mentions légales
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  </footer>
)

export default footer

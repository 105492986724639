import React from "react"
import Header from "./header"
import Footer from "./footer"
import CallButton from "./callButton"

const layout = ({ children }) => (
  <div>
    <Header />
    {children}
    <Footer />
    <CallButton />
  </div>
)

export default layout
